import React, {useState, useEffect} from 'react';
import {GoogleLogin} from '@react-oauth/google';
import config from './config';

function App() {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const savedToken = sessionStorage.getItem('authToken');
        if (savedToken) {
            fetchUserData(savedToken);
        } else {
            setLoading(false);
        }
    }, []);

    // Fetch user data with saved token
    const fetchUserData = async (savedToken) => {
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/protected`, {
                headers: {
                    'Authorization': `Bearer ${savedToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setUser(data.user);
                setToken(savedToken);
            } else {
                // If token is invalid, clear storage
                sessionStorage.removeItem('authToken');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            sessionStorage.removeItem('authToken');
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            console.log('Google Response:', {
                hasCredential: !!credentialResponse.credential,
                credentialLength: credentialResponse.credential?.length
            });

            const response = await fetch(`${config.apiBaseUrl}/api/auth/google`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({token: credentialResponse.credential}),
            });

            console.log('API Response Status:', response.status);

            const data = await response.json();
            console.log('API Response Data:', data);

            if (!response.ok) {
                throw new Error(data.details || 'Login failed');
            }

            setUser(data);
            setToken(data.token);
            sessionStorage.setItem('authToken', data.token);

        } catch (error) {
            console.error('Login Error:', {
                message: error.message,
                stack: error.stack
            });
            alert(`Login failed: ${error.message}`);
        }
    };

    const handleGoogleError = () => {
        console.error('Google login failed');
        alert('Google login failed. Please try again.');
    };

    const handleLogout = () => {
        setUser(null);
        setToken(null);
        sessionStorage.removeItem('authToken');
    };

    if (loading) {
        return (
            <div className="min-h-screen bg-gray-100 flex items-center justify-center">
                <div className="text-lg">Loading...</div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <nav className="bg-white shadow-lg">
                <div className="max-w-6xl mx-auto px-4">
                    <div className="flex justify-between items-center h-16">
                        <div className="text-xl font-semibold">Pixel Probe</div>
                        <div>
                            {!user ? (
                                <div className="flex items-center">
                                    <GoogleLogin
                                        onSuccess={handleGoogleSuccess}
                                        onError={handleGoogleError}
                                        useOneTap
                                    />
                                </div>
                            ) : (
                                <div className="flex items-center space-x-4">
                                    {user.picture && (
                                        <img
                                            src={user.picture}
                                            alt="Profile"
                                            className="w-8 h-8 rounded-full"
                                        />
                                    )}
                                    <span className="text-gray-700">{user.name}</span>
                                    <button
                                        onClick={handleLogout}
                                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-200"
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>

            <main className="max-w-6xl mx-auto mt-8 px-4">
                {user ? (
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h1 className="text-2xl font-bold mb-4">Welcome, {user.name}!</h1>
                        <p className="text-gray-600">You are now logged in with your Google account.</p>
                        <p className="text-gray-600">Email: {user.email}</p>
                    </div>
                ) : (
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h1 className="text-2xl font-bold mb-4">Welcome to Pixel Probe</h1>
                        <p className="text-gray-600">Please sign in with Google to continue.</p>
                    </div>
                )}
            </main>
        </div>
    );
}

export default App;